import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PartnersList } from "../components/Lists/PartnersList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";

export const Partners = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [Partners, setCustomers_partners] = useState([]);

    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + '/Partners/getAllPartners_SP',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((responseJson) => {
                // Do something with the response
                console.log("todayDAta", responseJson.data)
                setloading(false);
                if (responseJson.data != null) {


                    setCustomers_partners(responseJson.data);

                }
            }).catch((error) => {
                setloading(false);
                console.log(error)

            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <PartnersList PartnersCL={Partners} fetchData={fetchData} />
    )

}